import React from "react";
import { AiOutlineHome, AiOutlineMail } from "react-icons/ai";
import { BiPhoneCall, BiInfoCircle } from "react-icons/bi";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Container from "../components/Container";
import "../components/WhyFruitSubscription.css"

const contactSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(/^\d{10}$/, "Enter a valid 10-digit mobile number"),
  comment: yup.string().required("Comments are required"),
});

const Contact = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      comment: "",
    },
    validationSchema: contactSchema,
    onSubmit: async (values) => {
      try {
        await axios.post("https://your-backend-url.com/send-email", values);
        alert("Your message has been sent successfully!");
      } catch (error) {
        alert("Failed to send your message. Please try again.");
      }
    },
  });

  return (
    <Container class1="contact-wrapper py-5 home-wrapper-2">
      <div className="row">
        {/* Google Maps Section */}
        <div className="col-12 mb-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6986.771103663534!2d76.99275607711007!3d28.886888929272477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390da5e51463d4c9%3A0xe5a485e2ac7c3d4a!2sMandaura%2C%20Haryana%20131103!5e0!3m2!1sen!2sin!4v1669909087902!5m2!1sen!2sin"
            width="100%"
            height="450"
            className="border-0"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        {/* Contact Form and Details */}
        <div className="col-12 d-flex flex-column flex-md-row gap-4">
          {/* Contact Form */}
          <div className="form-section flex-grow-1">
            <h3 className="contact-title mb-4">Contact</h3>
            <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-3">
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="error">{formik.errors.name}</div>
              )}

              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="error">{formik.errors.email}</div>
              )}

              <input
                type="tel"
                className="form-control"
                placeholder="Mobile Number"
                name="mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
              />
              {formik.touched.mobile && formik.errors.mobile && (
                <div className="error">{formik.errors.mobile}</div>
              )}

              <textarea
                className="form-control"
                placeholder="Comments"
                name="comment"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.comment}
              />
              {formik.touched.comment && formik.errors.comment && (
                <div className="error">{formik.errors.comment}</div>
              )}

              <button className="button border-0">Submit</button>
            </form>
          </div>

          {/* Contact Details */}
          <div className="details-section flex-grow-1">
            <h3 className="contact-title mb-4">Get in Touch with Us</h3>
            <ul className="ps-0">
              <li className="mb-3 d-flex align-items-center gap-3">
                <AiOutlineHome className="fs-5" />
                <address className="mb-0">
                  Daiict college, Reliance Cross Rd, Gandhinagar, Bengaluru, PinCode: 560078
                </address>
              </li>
              <li className="mb-3 d-flex align-items-center gap-3">
                <BiPhoneCall className="fs-5" />
                <a href="tel:+919959371639">+91 99593 71639</a>
              </li>
              <li className="mb-3 d-flex align-items-center gap-3">
                <AiOutlineMail className="fs-5" />
                <a href="mailto:info@fruitsy.club">info@fruitsy.club</a>
              </li>
              <li className="mb-3 d-flex align-items-center gap-3">
                <BiInfoCircle className="fs-5" />
                <p className="mb-0">Monday – Friday: 10 AM – 8 PM</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
