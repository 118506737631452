import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useLocation } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  arrayUnion,
  collection,
  getDocs,
  limit,
  query
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useAuth } from "../firebase/auth_context";
import { useNavigate } from "react-router-dom";
import Container from "../components/Container";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../components/SingleProduct.css"; // Custom CSS file for additional styling

const SingleProduct = () => {
  const location = useLocation();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const productId = location.pathname.split("/")[2];
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  // State for dropdown selections
  const [quantity, setQuantity] = useState("");
  const [fruitTime, setFruitTime] = useState("");
  const [days, setDays] = useState("");

  const fetchProductFromFirestore = async () => {
    const db = getFirestore();
    const productRef = doc(db, "Fruitsy", "products", "productid", productId);
    try {
      const productSnapshot = await getDoc(productRef);
      if (productSnapshot.exists()) {
        const productData = productSnapshot.data();

        if (productData?.images) {
          const storage = getStorage();
          const imageRef = ref(storage, productData.images);
          const imageUrl = await getDownloadURL(imageRef);
          productData.imageURL = imageUrl;
        }
        setProduct(productData);
      } else {
        console.error("Product does not exist.");
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch featured products
  const fetchFeaturedProducts = async () => {
    const db = getFirestore();
    const productsRef = collection(db, "Fruitsy", "products", "productid");
    const featuredQuery = query(productsRef, limit(4)); // Fetch only 4 products

    try {
      const snapshot = await getDocs(featuredQuery);
      const productsList = [];

      for (const docSnap of snapshot.docs) {
        const productData = docSnap.data();

        if (productData?.images) {
          const storage = getStorage();
          const imageRef = ref(storage, productData.images);
          const imageUrl = await getDownloadURL(imageRef);
          productData.imageURL = imageUrl;
        }

        productsList.push({ id: docSnap.id, ...productData });
      }
      setFeaturedProducts(productsList);
    } catch (error) {
      console.error("Error fetching featured products:", error);
    }
  };

  useEffect(() => {
    fetchProductFromFirestore();
    fetchFeaturedProducts();
  }, [productId]);

  const [isAddingToCart, setIsAddingToCart] = useState(false); // New state to track the button state

  const addToCart = async () => {
    if (!currentUser) {
      toast.error("Kindly login to add the product to your cart.");
      navigate("/login"); // Redirect to the login page
      return;
    }

    if (!product?.name || !product?.price || !product?.imageURL) {
      console.error("Product data is incomplete:", product);
      return;
    }

    if (!quantity || !fruitTime || !days) {
      toast.error("Please select quantity, time, and days before adding to cart.");
      return;
    }

    setIsAddingToCart(true); // Set the state to true when button is clicked to disable the button

    const db = getFirestore();
    const userCartRef = doc(
      db,
      "Fruitsy",
      "users",
      "userId",
      currentUser.email,
      "cart",
      productId
    );

    try {
      const cartSnapshot = await getDoc(userCartRef);

      if (cartSnapshot.exists()) {
        await setDoc(
          userCartRef,
          {
            items: arrayUnion({
              productId,
              title: product?.name,
              price: product?.price,
              quantity,
              fruitTime,
              days,
              imageURL: product?.imageURL,
            }),
          },
          { merge: true }
        );
      } else {
        await setDoc(userCartRef, {
          items: [
            {
              productId,
              title: product?.name,
              price: product?.price,
              quantity,
              fruitTime,
              days,
              imageURL: product?.imageURL,
            },
          ],
        });
      }
      toast.success("Product added to cart successfully!");
    } catch (error) {
      console.error("Error adding product to cart:", error);
      toast.error("Error adding product to cart. Please try again.");
    } finally {
      setIsAddingToCart(true); // Reset the button state after operation
    }
  };

  if (loading) {
    return <p>Loading product...</p>;
  }

  return (
    <Container>
      <div
        className="product-page"
        style={{
          background: "linear-gradient(to bottom, #fff, #fff)",
          minHeight: "100vh",
          padding: "2rem",
        }}
      >
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="card product-card shadow-lg border-0 rounded">
              <div className="row g-0">
                {/* Image Section */}
                <div className="col-md-6 p-3">
                  <div className="product-image1">
                    <img
                      src={product?.imageURL || "https://via.placeholder.com/600"}
                      alt={product?.name}
                      className="img-fluid rounded shadow"
                      style={{ transition: "transform 0.3s ease-in-out" }}
                      onMouseOver={(e) => (e.target.style.transform = "scale(1.05)")}
                      onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
                    />
                  </div>
                </div>

                {/* Details Section */}
                <div className="col-md-6 p-4">
                  <h2 className="product-title1 text-dark fw-bold mb-3">
                    {product?.name}
                  </h2>
                  <p className="product-price12 h4 text-primary mb-3">
                    Rs. {product?.price}/-
                  </p>
                  <ReactStars
                    count={5}
                    size={24}
                    value={product?.ratings || 0}
                    edit={false}
                    activeColor="#ffd700"
                  />

                  {/* Dropdowns */}
                  <div className="mb-3">
                    <label className="form-label fw-bold">Quantity:</label>
                    <select
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      className="form-select"
                    >
                      <option value="">Choose an option</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label fw-bold">Your Fruit Time:</label>
                    <select
                      value={fruitTime}
                      onChange={(e) => setFruitTime(e.target.value)}
                      className="form-select"
                    >
                      <option value="">Choose an option</option>
                      <option value="Morning">Morning</option>
                      <option value="Afternoon">Afternoon</option>
                      <option value="Evening">Evening</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label fw-bold">Days:</label>
                    <select
                      value={days}
                      onChange={(e) => setDays(e.target.value)}
                      className="form-select"
                    >
                      <option value="">Choose an option</option>
                      <option value="7">7</option>
                      <option value="30">30</option>
                    </select>
                  </div>

                  {/* Add to Cart Button */}
                  <button
                    className="btn btn-primary btn-lg w-100 mt-3 shadow-sm"
                    onClick={addToCart}
                    disabled={isAddingToCart} // Disable the button while adding to cart
                  >
                    {isAddingToCart ? "Adding..." : "Add to Cart"}
                  </button>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Product Description Section */}
        <div className="product-details-container p-4 bg-light">
          <h2 className="fw-bold mb-3">Description</h2>
          <h3 className="fw-bold text-dark mb-4">{product?.name}</h3>
          <p dangerouslySetInnerHTML={{ __html: product?.description }} />
        </div>

        {/* Featured Products Section */}
        <section>
          <div className="featured-products mt-5">
            <h3>Featured Products</h3>
            <div className="row">
              {featuredProducts.map((featuredProduct) => (
                <div key={featuredProduct.id} className="col-12 col-md-3">
                  <div className="product-card2">
                    <img
                      src={featuredProduct.imageURL}
                      alt={featuredProduct.name}
                      className="img-fluid"
                    />
                    <h5>{featuredProduct.name}</h5>
                    <p>Rs. {featuredProduct.price}</p>
                    <button
                      className="btn"
                      onClick={() => navigate(`/product/${featuredProduct.id}`)}
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Container>
  );
};

export default SingleProduct;
