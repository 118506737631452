import React, { useEffect , useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import BlogCard from "../components/BlogCard";
import ProductCard from "../components/ProductCard";
import SpecialProduct from "../components/SpecialProduct";
import Container from "../components/Container";
import PricingCard from "../components/PricingCard";
import { services } from "../utils/Data";
import wish from "../images/wish.svg";
import wishlist from "../images/wishlist.svg";
import addcart from "../images/add-cart.svg";
import view from "../images/view.svg";
import '../components/Pricing.css';
import backgroundImage from "../images/tasty-image.png";
import Testimonials from "../components/Testimonials";

import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import slide from "../images/slide-img.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../features/blogs/blogSlice";
import moment from "moment";
import { getAllProducts } from "../features/products/productSlilce";
import ReactStars from "react-rating-stars-component";
import { addToWishlist } from "../features/products/productSlilce";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import Slider from "react-slick";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  arrayUnion,
  collection,getDocs,limit,query
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useLocation } from "react-router-dom";



const Home = () => {
  const blogState = useSelector((state) => state?.blog?.blog);
  const productState = useSelector((state) => state?.product?.product);

  const location = useLocation();
    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(true);
    const productId = location.pathname.split("/")[2];
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    
  };
  
const fetchFeaturedProducts = async () => {
  const db = getFirestore();
  const productsRef = collection(db, "Fruitsy", "products", "productid");
  const featuredQuery = query(productsRef, limit(4)); // Fetch only 4 products

  try {
    const snapshot = await getDocs(featuredQuery);
    const productsList = [];

    for (const docSnap of snapshot.docs) {
      const productData = docSnap.data();

      if (productData?.images) {
        const storage = getStorage();
        const imageRef = ref(storage, productData.images);
        const imageUrl = await getDownloadURL(imageRef);
        productData.imageURL = imageUrl;
      }

      productsList.push({ id: docSnap.id, ...productData });
    }
    setFeaturedProducts(productsList);
  } catch (error) {
    console.error("Error fetching featured products:", error);
  }
};
  useEffect(() => {
    
  fetchFeaturedProducts();
  }, [productId]);
  useEffect(() => {
    getblogs();
    getProducts();
  }, []);
  const getblogs = () => {
    dispatch(getAllBlogs());
  };

  const getProducts = () => {
    dispatch(getAllProducts());
  };

  const addToWish = (id) => {
    //alert(id);
    dispatch(addToWishlist(id));
  };
  return (
    <>
      <section className="slider_section position-relative">
      <Carousel
        id="carouselExampleControls"
        interval={settings.autoplaySpeed}
        ride={settings.autoplay ? "carousel" : ""}
        wrap={settings.infinite}
        slide={true}
      >
        <Carousel.Item>
          <div className="slider_item-box">
            <div className="slider_item-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="slider_item-detail">
                      <h1>Enjoy The Fruit Bowls Made Specially For You <br /></h1>
                      <p>
                      Welcome to Fruitsy! Get your daily dose of fresh, cut fruits delivered to your door – because who has time to chop when you can just snack and smile?
                      </p>
                      <div class="d-flex">
                      <a href="/product" class="text-uppercase custom_orange-btn me-3">
                        Shop Now
                      </a>
                      <a href="/contact" class="text-uppercase custom_dark-btn">
                        Contact Us
                      </a>
                    </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="slider_img-box">
                      <img src="images/1.png" alt="Fruit Plate" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="slider_item-box">
            <div className="slider_item-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="slider_item-detail">
                      <h1>Fresh Fruits Delivered to You</h1>
                      <p>
                        Enjoy the freshness of handpicked fruits, delivered to
                        your doorstep every day. Taste the best from nature!
                      </p>
                      <div class="d-flex">
                      <a href="/product" class="text-uppercase custom_orange-btn me-3">
                        Shop Now
                      </a>
                      <a href="/contact" class="text-uppercase custom_dark-btn">
                        Contact Us
                      </a>
                    </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="slider_img-box">
                      <img src="images/slide-img.png" alt="Fruit Delivery" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
      
    </section>
    <section>
    <div className="healthy">
      <div className="slider_item-box">
            <div className="slider_item-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="slider_item-detail">
                      <h2>Stay consistent for a healthier you.</h2>
                      <p>
                      Our bowls are made fresh every day for the best flavor and nutrition. Choose from weekly, bi-weekly, or monthly subscriptions to fit your needs.
                      </p>
                      <ul>
                        <li>
                        Summer Refresh Fruit Pack
                        </li>
                        <li>
                        Immunity Boost Fruit Pack
                        </li>
                        <li>
                        Mom’s Fresh Fruit Pack
                        </li>
                        <li>
                        Women’s Health Fruit Pack
                        </li>
                        <li>
                        Skin & Hair Nourishment Pack
                        </li>
                        <li>
                        Diabetes-Friendly Fruit Pack
                        </li>
                        <li>
                        Weight Management Fruit Pack
                        </li>
                      </ul>
                      <a href="/product" class="text-uppercase custom_orange-btn me-3">
                        Subscribe
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="slider_img-box">
                      <img src="images/3.png" alt="Fruit Plate" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          </section>
    <section className="relative z-10 overflow-hidden bg-white pb-12 pt-20 dark:bg-gray-900 lg:pb-24 lg:pt-32">
   
      <div className="container mx-auto px-4">
      <div className=" pricing-container1">
        
        <div className="flex flex-wrap justify-center">
          <div className="w-full px-4">
            <div className="mx-auto mb-16 max-w-lg text-center pd-12">
              
              <h2 className="mb-4 text-3xl font-bold leading-tight text-gray-800 dark:text-white sm:text-4xl md:text-5xl">
              Featured Products
              </h2>
              <p className="text-base text-gray-600 dark:text-gray-400">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form.
              </p>
            </div>
          </div>
        </div>

        {/* Featured Products Section */}
     <section>
  <div className="featured-products mt-5">
    
    <div className="row">
      {featuredProducts.map((featuredProduct) => (
        <div key={featuredProduct.id} className="col-12 col-md-3">
          <div className="product-card2">
            <img
              src={featuredProduct.imageURL}
              alt={featuredProduct.name}
              className="img-fluid"
            />
            <h5>{featuredProduct.name}</h5>
            <p>Rs. {featuredProduct.price}</p>
            <button
              className="btn"
              onClick={() =>navigate(`/product/${featuredProduct.id}`)}
            >
              Subscribe
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>
      </div>
      </div>
    </section>
      <div className="banner1" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <h1 className="banner1-text">VERY TASTY FRUITS</h1>
    </div>
    <section>
    <div className="healthy">
      <div className="slider_item-box">
            <div className="slider_item-container">
              <div className="container">
                <div className="row">
                <div className="col-md-6">
                    <div className="slider_img-box">
                      <img src="images/4.png" alt="Fruit Plate" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    
                    <div className="slider_item-detail">
                      <h2>Consistency is the key to healthy living.</h2>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form, by injected humour, or randomised words which
                        don't look even slightly believable.
                      </p>
                      <a href="/product" class="text-uppercase custom_orange-btn me-3">
                        Subscribe
                      </a>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          </div>
          </section>
    <section>
          <Testimonials />
        </section>
      
      
    </>
  );
};

export default Home;
const List = ({ children }) => {
  return (
    <p className="text-base text-body-color dark:text-dark-6">{children}</p>
  );
};