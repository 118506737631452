import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { auth } from "../firebase/FirebaseConfig"; // Adjust the import to your Firebase configuration
import { onAuthStateChanged } from "firebase/auth"; // Import Firebase method to track auth state

export const OpenRoutes = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the user is authenticated
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false); // Set loading to false after checking auth state
    });

    return () => unsubscribe(); // Cleanup subscription on component unmount
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while checking auth state
  }

  return isAuthenticated ? <Navigate to="/" replace={true} /> : children; // Redirect if authenticated
};
