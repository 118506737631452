import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import Container from "../components/Container";
import backgroundImage from "../images/tasty-image.png";
import { useAuth } from "../firebase/auth_context";
import { db } from "../firebase/FirebaseConfig"; // Adjust the path to your Firebase config
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
const Cart = () => {
  const [userCartState, setUserCartState] = useState([]); // State to store cart data
  const [totalAmount, setTotalAmount] = useState(0); // State to calculate total amount
  useEffect(() => {
    localStorage.setItem("cartState", JSON.stringify(userCartState));
  }, [userCartState]);
  const { currentUser } = useAuth();
  // Fetch cart data from Firestore
  const fetchCartData = async () => {
    if (!currentUser || !currentUser.email) {
      console.error("No current user or email found");
      return [];
    }
    console.log("Current User Email:", currentUser?.email);


    try {
      // Correct path: Fruitsy -> users -> {user.email} -> cart
      const cartRef = collection(db, "Fruitsy", "users","userId", currentUser.email, "cart");
      const querySnapshot = await getDocs(cartRef);
      console.log("Cart Snapshot:", querySnapshot.docs.map(doc => doc.data()));

      const cartData = querySnapshot.docs.map((doc) => ({
        _id: doc.id,
        items: doc.data().items || [], // Fetch the 'items' array
      }));

      return cartData;
    } catch (error) {
      console.error("Error fetching cart data:", error);
      return [];
    }
  };

  // Update product quantity in Firestore
  const updateProductQuantityInFirestore = async (cartItemId, updatedItems) => {
    try {
      const cartItemRef = doc(db, "Fruitsy", "users","userId", currentUser.email, "cart", cartItemId);
      await updateDoc(cartItemRef, { items: updatedItems });
    } catch (error) {
      console.error("Error updating product quantity:", error);
    }
  };

  // Delete product from Firestore
  const deleteCartProductFromFirestore = async (cartItemId) => {
    try {
      const cartItemRef = doc(db, "Fruitsy", "users","userId", currentUser.email, "cart", cartItemId);
      await deleteDoc(cartItemRef);
    } catch (error) {
      console.error("Error deleting cart product:", error);
    }
  };

  // Fetch cart data on component mount
  useEffect(() => {
    if (!currentUser || !currentUser.email) return;

    const getCartData = async () => {
      const cartData = await fetchCartData();
      setUserCartState(cartData);
    };

    getCartData();
  }, [currentUser]);

  // Calculate total amount whenever cart state updates
  useEffect(() => {
    const sum = userCartState.reduce(
      (acc, cart) =>
        cart.items.reduce(
          (itemAcc, item) => itemAcc + item.quantity * item.price,
          acc
        ),
      0
    );
    setTotalAmount(sum);
  }, [userCartState]);

  // Handle quantity update
  const updateProductQuantity = async (cartItemId, productId, quantity) => {
    setUserCartState((prevCart) =>
      prevCart.map((cart) => ({
        ...cart,
        items: cart.items.map((item) =>
          item.productId === productId ? { ...item, quantity: Number(quantity) } : item
        ),
      }))
    );

    // Update Firestore with updated quantity
    const cartToUpdate = userCartState.find((cart) => cart._id === cartItemId);
    const updatedItems = cartToUpdate.items.map((item) =>
      item.productId === productId ? { ...item, quantity: Number(quantity) } : item
    );

    await updateProductQuantityInFirestore(cartItemId, updatedItems);
  };

  // Handle product deletion
  const deleteACartProduct = async (cartItemId, productId) => {
    setUserCartState((prevCart) =>
      prevCart.map((cart) => ({
        ...cart,
        items: cart.items.filter((item) => item.productId !== productId),
      }))
    );

    // Update Firestore by deleting the product
    const cartToUpdate = userCartState.find((cart) => cart._id === cartItemId);
    const updatedItems = cartToUpdate.items.filter((item) => item.productId !== productId);

    if (updatedItems.length > 0) {
      await updateProductQuantityInFirestore(cartItemId, updatedItems);
    } else {
      // Delete entire cart document if no items remain
      await deleteCartProductFromFirestore(cartItemId);
    }
  };

  return (
    <>
      <section>
        <div
          className="banner1"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <h3 className="wishlist1-text">Cart</h3>
        </div>
      </section>
      <Container class1="cart-wrapper home-wrapper-2 py-5">
        {userCartState.length === 0 || userCartState.every((cart) => cart.items.length === 0) ? (
          <div className="text-center">
            <h3>Your cart looks light!</h3>
            <p>Add items to your cart to get started.</p>
            <Link to="/" className="button">
              Continue Shopping
            </Link>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12">
                <div className="cart-header py-3 d-flex justify-content-between align-items-center">
                  <h4 className="cart-col-1">Product</h4>
                  <h4 className="cart-col-2">Price</h4>
                  <h4 className="cart-col-3">Quantity</h4>
                  <h4 className="cart-col-4">Total</h4>
                </div>
                {userCartState.map((cart) =>
                  cart.items.map((item) => (
                    <div
                      key={item.productId}
                      className="cart-data py-3 mb-2 d-flex justify-content-between align-items-center"
                    >
                      <div className="cart-col-1 gap-15 d-flex align-items-center">
                        <div className="w-25">
                          <img
                            src={item.imageURL}
                            className="img-fluid"
                            alt={item.title}
                          />
                        </div>
                        <div className="w-75">
                          <p>{item.title}</p>
                        </div>
                      </div>
                      <div className="cart-col-2">
                        <h5 className="price">Rs. {item.price}</h5>
                      </div>
                      <div className="cart-col-3 d-flex align-items-center gap-15">
                        <input
                          className="form-control"
                          type="number"
                          min={1}
                          value={item.quantity}
                          onChange={(e) =>
                            updateProductQuantity(cart._id, item.productId, e.target.value)
                          }
                        />
                        <AiFillDelete
                          onClick={() => deleteACartProduct(cart._id, item.productId)}
                          className="text-danger"
                        />
                      </div>
                      <div className="cart-col-4">
                        <h5 className="price">Rs. {item.quantity * item.price}</h5>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="col-12 py-2 mt-4">
                <div className="d-flex justify-content-between align-items-baseline">
                  <Link to="/" className="button">
                    Continue To Shopping
                  </Link>
                  <div className="d-flex flex-column align-items-end">
                    <h4>SubTotal: Rs. {totalAmount}</h4>
                    <p>Taxes and shipping calculated at checkout</p>
                    <Link to="/checkout" className="button">
                      Checkout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default Cart;
