import React, { useContext, useEffect, useState } from "react";
import { onAuthStateChanged, getAuth,sendPasswordResetEmai } from "firebase/auth";
import  app  from "./FirebaseConfig"; // Your Firebase initialization file

const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth(app); // Initialize Firebase Auth
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user); // Set the logged-in user
      setLoading(false);   // Stop loading once auth state is known
    });

    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  const value = { currentUser };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
