import React from 'react';
import './SubscriptionOption.css';

const SubscriptionOptions = () => {
  const options = [
    {
      id: 1,
      title: 'Weekly Subscription',
      description: 'Fresh and Flavorful Fruit Bowls Delivered to Your Doorstep Every Week',
    },
    {
      id: 2,
      title: 'Bi-Weekly Subscription',
      description: 'Enjoy Delicious and Nutritious Fruit Bowls Every Other Week with Fruut.in',
    },
    {
      id: 3,
      title: 'Monthly Subscription',
      description: 'Get Your Monthly Dose of Vitamins and Antioxidants with Fruut.in\'s Fruit Bowls Subscription',
    },
    {
      id: 4,
      title: 'Custom Subscription',
      description: 'Create Your Own Subscription Plan and Never Run Out of Your Favorite Fruits with Fruut.in',
    },
  ];

  return (
    <div className="subscription-container">
      <h1 className="subscription-title">Subscription-Options</h1>
      <div className="subscription-grid">
        {options.map((option) => (
          <div className="subscription-card" key={option.id}>
            <div className="icon-placeholder">
              <img
                src="https://via.placeholder.com/60x60/FF0000/FFFFFF?text=ICON"
                alt="icon"
              />
            </div>
            <h2 className="subscription-heading">{option.title}</h2>
            <p className="subscription-description">{option.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionOptions;
