import React, { useEffect, useState } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import ProductCard from "../components/ProductCard";
import Container from "../components/Container";
import { collection, getDocs, doc } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";
import "../App.css";
import SubscriptionOptions from "../components/SubscriptionOption";
import WhyFruitSubscription from "../components/WhyFruitSubscription";

const OurStore = () => {
  const [products, setProducts] = useState([]); // State to hold products
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch products from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(false);

        // Reference to the 'productid' subcollection
        const productRef = collection(doc(db, "Fruitsy", "products"), "productid");
        const querySnapshot = await getDocs(productRef);

        // Map documents to a list of products
        const productList = querySnapshot.docs.map((doc) => ({
          id: doc.id, // Document ID
          ...doc.data(), // Document data
        }));

        setProducts(productList); // Update state with products
        setLoading(false);
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Failed to fetch products.");
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <>
    <section className="slider_section position-relative">
    <div className="slider_item-box">
            <div className="slider_item-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="slider_item-detail">
                      <h1>Fruit bowl <br />Subscription</h1>
                      <p>
                      Welcome to our fresh cut Fruut Bowl Subscription service! We offer a variety of subscription packages to fit your needs and taste preferences. Our fruit is always fresh, delicious, and expertly cut, so you can enjoy the best possible experience.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="slider_img-box">
                      <img src="images/6.png" alt="Fruit Plate" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </section>
      <Container class1="store-wrapper home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <h3 className="text-center mb-4">Our Products</h3>
            <div className="products-list pb-5">
              <div className="row g-5"> {/* Gap of 4px for better spacing */}
                {/* Loading State */}
                {loading && (
                  <div className="col-12 text-center">
                    <p>Loading products...</p>
                  </div>
                )}

                {/* Error State */}
                {error && (
                  <div className="col-12 text-center">
                    <p className="text-danger">{error}</p>
                  </div>
                )}

                {/* Empty State */}
                {!loading && !error && products.length === 0 && (
                  <div className="col-12 text-center">
                    <p>No products found.</p>
                  </div>
                )}

                {/* Render Product Cards */}
                {!loading &&
                  !error &&
                  products.map((product) => (
                    <div key={product.id} className="col-12 col-md-4"> {/* 3 products per row */}
                      <ProductCard data={product} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <SubscriptionOptions/>
      <section>
        <WhyFruitSubscription/>
      </section>
    </>
  );
};

export default OurStore;
