import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import Container from "../components/Container";
import backgroundImage from "../images/tasty-image.png";
import { useAuth } from "../firebase/auth_context";
import { db } from "../firebase/FirebaseConfig";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import "../components/Orders.css"

const Orders = () => {
  const [userOrdersState, setUserOrdersState] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const { currentUser } = useAuth();

  // Fetch orders data from Firestore
  const fetchOrdersData = async () => {
    try {
      const ordersRef = collection(db, "Fruitsy", "users", "userId", currentUser.email, "orders");
      const querySnapshot = await getDocs(ordersRef);
      const ordersData = querySnapshot.docs.map((doc) => ({
        _id: doc.id,
        ...doc.data(),
      }));
      return ordersData;
    } catch (error) {
      console.error("Error fetching orders data:", error);
      return [];
    }
  };

  // Update order status to "canceled" in Firestore
  const cancelOrderInFirestore = async (orderId) => {
    try {
      // User-specific orders collection
      const userOrderRef = doc(db, "Fruitsy", "users", "userId", currentUser.email, "orders", orderId);

      // Global orders collection
      const globalOrderRef = doc(db, "Fruitsy", "orders","orderId", orderId);

      // Update both collections
      const updatedData = {
        status: "canceled",
        canceledAt: new Date().toISOString(),
      };

      await Promise.all([
        updateDoc(userOrderRef, updatedData), // Update in user's orders collection
        updateDoc(globalOrderRef, updatedData), // Update in global orders collection
      ]);
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  // Fetch orders data dynamically on component mount
  useEffect(() => {
    const getOrdersData = async () => {
      const ordersData = await fetchOrdersData();
      setUserOrdersState(ordersData);
    };

    getOrdersData();
  }, []);

  // Calculate total amount whenever the orders state updates
  useEffect(() => {
    const sum = userOrdersState.reduce(
      (acc, order) =>
        acc +
        order.cartItems.reduce(
          (subAcc, item) => subAcc + item.price * item.quantity,
          0
        ),
      0
    );
    setTotalAmount(sum);
  }, [userOrdersState]);

  // Handle order cancellation
  const cancelOrder = async (order) => {
    await cancelOrderInFirestore(order._id);

    // Update local state to reflect the canceled status
    setUserOrdersState((prevOrders) =>
      prevOrders.map((o) =>
        o._id === order._id ? { ...o, status: "canceled" } : o
      )
    );
  };

  return (
    <>
      <section>
        <div
          className="banner1"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <h3 className="wishlist1-text">Your Orders</h3>
        </div>
      </section>
      <Container class1="orders-wrapper home-wrapper-2 py-5">
        {userOrdersState.length === 0 ? (
          <div className="text-center">
            <h3>No Orders Found!</h3>
            <p>Browse our products and place an order.</p>
            <Link to="/" className="button">
              Continue Shopping
            </Link>
          </div>
        ) : (
          <div>
            <table className="orders-table">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Details</th>
                  <th>Total Amount</th>
                  <th>Days</th>
                  <th>Fruit Time</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {userOrdersState.map((order) => (
                  <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>
                      {order.cartItems.map((item, index) => (
                        <p key={index}>
                          {item.title} - {item.quantity} x Rs. {item.price}
                        </p>
                      ))}
                    </td>
                    <td>
                      Rs.{" "}
                      {order.cartItems.reduce(
                        (acc, item) => acc + item.price * item.quantity,
                        0
                      )}
                    </td>
                    <td>{order.cartItems[0]?.days || "-"}</td>
                    <td>{order.cartItems[0]?.time || "-"}</td>
                    <td>
                      {order.status === "canceled" ? (
                        <span className="text-danger">Canceled</span>
                      ) : (
                        <AiFillDelete
                          onClick={() => cancelOrder(order)}
                          className="text-danger"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-center text-md-end mt-4">
              <Link to="/" className="button mb-3 mb-md-0">
                Continue Shopping
              </Link>
              <div>
                <h4>Total Orders Amount: Rs. {totalAmount}</h4>
                <p>Orders summary and details above</p>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};  

export default Orders;
