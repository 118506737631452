import React from "react";
import backgroundImage from "../images/tasty-image.png";
import '../components/Pricing.css';
/*css page is pricing.css*/
import Team from "../components/Team";

const About = () => {
  return (<>
  <section>
    <div className="banner1" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <h3 className="wishlist1-text">About Us</h3></div></section>
      <div className="our-company justify-center">
          <div className="w-full px-4">
            <div className="mx-auto mb-16 max-w-lg text-center pd-12">
              <span className="company mb-2 block text-lg font-semibold text-blue-600">
              Quality You Can Taste, Freshness You Can Feel, Convenience You Deserve
              </span>
              <h2 className="company1 mb-4 text-3xl font-bold leading-tight text-gray-800 dark:text-white sm:text-4xl md:text-5xl">
                Who We Are
              </h2>
              <p className="company2 text-base text-gray-600 dark:text-gray-400">
              Welcome to Fruitsy, your go-to destination for fresh, pre-cut fruits delivered straight to your door. We believe in making healthy snacking simple, convenient, and delicious. Whether you're looking for a quick snack, a refreshing treat, or a boost of energy, we’ve got you covered with the freshest fruits, carefully sliced and ready to enjoy.
At Fruitsy, we take pride in offering high-quality, handpicked fruits, packed with nutrients and flavor. We know life gets busy, so we’ve made it easier for you to enjoy nature’s goodness without the hassle. Just order, relax, and let us handle the rest.
Snack smart, stay fresh, and smile with Fruitsy – because healthy should always be this easy!
              </p>
            </div>
          </div>
        </div>
      <section class="section1">
      <div class="container1">
        <article class="entity-side-image entity-image-left">
          <div class="entity-image"
          ><img class="w-100" src="../images/blog-1.jpg" alt=""/>
            </div>
          <div class="entity-content">
            <h2 class="entity-title">What is our mission?</h2>
            <p class="entity-subtext"></p>
            <p class="entity-text">At Fruitsy, our mission is to make healthy living simple and accessible. We are dedicated to providing fresh, high-quality, pre-cut fruits, delivered straight to your door. We aim to save you time and effort by offering a convenient and nutritious snack option, making it easier for everyone to enjoy the goodness of fresh fruit, anytime, anywhere. Our commitment is to promote healthier lifestyles, one slice at a time.</p>
          </div></article><article class="entity-side-image">
          <div class="entity-image">
            <img class="w-100" src="../images/best-fruits.webp" alt=""/>
            </div>
          <div class="entity-content">
            <h2 class="entity-title">Why choose us</h2>
            <p class="entity-subtext">How are we the best?</p>
            <p class="entity-text">At Fruitsy, we make healthy snacking easy and convenient. Our fresh, pre-cut fruits are delivered straight to your door, saving you time and effort. We focus on quality, freshness, and taste, so you can enjoy the best fruit without the hassle of chopping. Customer satisfaction is our priority, and we are committed to providing you with a seamless, enjoyable experience. With Fruitsy, eating healthy has never been so simple and delicious!</p></div>
        </article>
      </div>
    
    </section>
    <Team/>
    
    
    </>
      );
};

export default About;
