import React from 'react';
import './WhyFruitSubscription.css';

const WhyFruitSubscription = () => {
  return (
    <div className="container-sub">
      {/* Left Section */}
      <div className="content-section">
        <h1 className="title">Why Fruit Bowl Subscription?</h1>
        <p className="description">
          Are you a fruit lover? Do you want to make sure you always have fresh, delicious fruits on hand? A fruit bowl subscription might be just what you need! With a fruit bowl subscription, you can enjoy a variety of seasonal fruits delivered right to your doorstep on a regular basis. Not only is it convenient, but it’s also a great way to incorporate more fruits into your diet. So why not sign up for a fruit bowl subscription today and start enjoying the many benefits of this healthy and delicious service?
        </p>
      </div>

      {/* Right Section */}
      <div className="features-section">
        <h2 className="features-title">Product Features/<span className="">Benefits</span></h2>
        <ul className="features-list">
          <li>Convenience</li>
          <li>Ready To Eat</li>
          <li>Customization Available</li>
          <li>No Lapse Policy*</li>
          <li>Complimentary Products Every Week</li>
        </ul>
      </div>
    </div>
  );
};

export default WhyFruitSubscription;
