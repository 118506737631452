import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import wishlist from "../images/wishlist.svg";
import user from "../images/user.svg";
import cart from "../images/cart.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserCart } from "../features/user/userSlice";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase/FirebaseConfig";
import { HamburgetMenuClose,HamburgetMenuOpen } from "./icons";import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

function Header() {
  const dispatch = useDispatch();

  // Redux cart state
  const cartState = useSelector((state) => state?.auth?.cartProducts);

  // State to manage Firebase user
  const [firebaseUser, setFirebaseUser] = useState(null);

  // State to calculate total cart value
  const [total, setTotal] = useState(0);

  // State for hamburger menu
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  // Track Firebase authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setFirebaseUser(user);
      } else {
        setFirebaseUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  // Fetch cart data
  useEffect(() => {
    if (firebaseUser) {
      const token = firebaseUser.accessToken;
      const config2 = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };
      dispatch(getUserCart(config2));
    }
  }, [dispatch, firebaseUser]);

  // Calculate total cart value
  useEffect(() => {
    if (cartState?.length) {
      const sum = cartState.reduce(
        (acc, item) => acc + item.quantity * item.price,
        0
      );
      setTotal(sum);
    }
  }, [cartState]);

  // Handle logout
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setFirebaseUser(null);
        localStorage.clear();
        window.location.reload();
      })
      .catch((error) => console.error("Logout failed:", error.message));
  };

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          {/* Logo */}
          <NavLink exact to="/" className="nav-logo">
            <span>Fruitsy</span>
            <span className="icon">
              
            </span>
          </NavLink>

          {/* Hamburger Menu */}
          <div className="nav-icon" onClick={handleClick}>
            {click ? (
              <span className="icon">
                <HamburgetMenuClose />
              </span>
            ) : (
              <span className="icon">
                <HamburgetMenuOpen />
              </span>
            )}
          </div>

          {/* Navigation Links */}
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink exact to="/" activeClassName="active" className="nav-links" onClick={handleClick}>
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/my-orders" activeClassName="active" className="nav-links" onClick={handleClick}>
                Orders
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/product" activeClassName="active" className="nav-links" onClick={handleClick}>
                Fruits
              </NavLink>
            </li>

            {/* User Account */}
            {firebaseUser ? (
              <li className="nav-item">
                <Link to="/my-profile" className="nav-links">
                  <img src={user} alt="user" />
                  <span>Profile</span>
                </Link>
              </li>
            ) : (
              <li className="nav-item">
                <Link to="/login" className="nav-links">
                  <img src={user} alt="user" />
                  
                </Link>
              </li>
            )}

            {/* Cart */}
            <li className="nav-item">
              <Link to="/cart" className="nav-links">
                <img src={cart} alt="cart" />
               
              </Link>
            </li>

            {/* Logout */}
            {firebaseUser && (
  <li className="nav-item">
    <button
      className="nav-links border-0 bg-transparent text-black d-flex align-items-center"
      type="button"
      onClick={handleLogout}
    >
      <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
      
    </button>
  </li>
)}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Header;
