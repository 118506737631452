import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import "./ProductCard.css"; // Import the CSS file


const ProductCard = ({ grid, data, addToCart }) => {
  const navigate = useNavigate();
  const [isInCart, setIsInCart] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  // Convert Firebase Storage path to a download URL
  useEffect(() => {
    if (data?.images) {
      const imagePath = data.images;
  
      if (typeof imagePath === "string" && !imagePath.startsWith("gs://")) {
        const storage = getStorage();
        const imageRef = ref(storage, imagePath);
  
        getDownloadURL(imageRef)
          .then((url) => {
            setImageUrl(url);
          })
          .catch((error) => {
            console.error("Error fetching image URL:", error);
            setImageUrl("/placeholder.png"); // Default placeholder image
          });
      } else {
        setImageUrl("/placeholder.png");
      }
    }
  }, [data]);
  

  // Handle Add to Cart
  const handleAddToCart = () => {
    if (!isInCart) {
      addToCart(data);
      setIsInCart(true);
    }
  };

  return (
      <div className="product5-card">
      
        {/* Product Image */}
        <div
          className="product5-image"
          onClick={() => navigate(`/product/${data.id}`)}
        >
          <img
            src={imageUrl || "/placeholder.png"}
            alt={data?.name || "Product"}
          />
        </div>

        {/* Product Details */}
        <div className="product5-details">
          <h5 className="product5-title">
            {data?.name?.length > 60
              ? data?.name.substr(0, 60) + "..."
              : data?.name}
          </h5>
          <ReactStars
            count={5}
            size={20}
            value={data?.ratings || 0}
            edit={false}
            activeColor="#ffd700"
          />
          <p className="price">Rs. {data?.price || "N/A"}</p>
        </div>

        <div className="Subscribe">
  <button
    className={`btn ${isInCart ? "btn-success" : "btn-primary"}`}
    onClick={() => navigate(`/product/${data.id}`)} // Navigate to product details page
  >
    Subscribe
  </button>
</div>
</div>
  );
};

export default ProductCard;
