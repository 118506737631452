import React from 'react';

import '../components/Pricing.css';/*css*/

const teamMembers = [
  {
    name: 'Poul Blue',
    role: 'CEO',
    image: '../images/blog-3.webp', // replace with actual path
    description: 'There are many variations of passages of Lorem Ipsum available, but the majority'
  },
  {
    name: 'Anna Red',
    role: 'Leadership',
    image: '../images/blog-1.jpg', // replace with actual path
    description: 'There are many variations of passages of Lorem Ipsum available, but the majority'
  },
  {
    name: 'Andy Black',
    role: 'Manager',
    image: '../images/blog-4.webp', // replace with actual path
    description: 'There are many variations of passages of Lorem Ipsum available, but the majority'
  }
];

function Team() {
  return (
    <div className="team-section">
      <h2>Our team</h2>
      <p>The ones who lead us into the bright future</p>
      <div className="team-cards">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-card">
            <img src={member.image} alt={member.name} className="team-image" />
            <h3>{member.name}</h3>
            <p className="role">{member.role}</p>
            <p className="description">{member.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Team;
