
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../firebase/auth_context"; // Assuming you're using a custom AuthContext

export const PrivateRoutes = ({ children }) => {
  const { currentUser } = useAuth(); // Get the current user from your AuthContext

  return currentUser ? children : <Navigate to="/login" replace={true} />;
};


