import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Container from "../components/Container";
import { useFormik } from "formik";
import * as yup from "yup";
import { db } from "../firebase/FirebaseConfig"; // Firebase configuration
import { collection, doc, setDoc, getDocs } from "firebase/firestore";
import { useAuth } from "../firebase/auth_context";

let shippingSchema = yup.object({
  firstname: yup.string().required("First Name is Required"),
  lastname: yup.string().required("Last Name is Required"),
  address: yup.string().required("Address is Required"),
  state: yup.string().required("State is Required"),
  city: yup.string().required("City is Required"),
  country: yup.string().required("Country is Required"),
  pincode: yup.number().required("Pincode is Required").positive().integer(),
  other: yup.string(),
});

const Checkout = () => {
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchCart = async () => {
      try {
        const cartRef = collection(db, "Fruitsy", "users","userId", currentUser.email, "cart");
        const cartSnapshot = await getDocs(cartRef);

        let cartItems = [];
        let totalSum = 0;

        cartSnapshot.forEach((doc) => {
          const productData = doc.data();

          if (productData.items) {
            productData.items.forEach((item) => {
              cartItems.push({
                
                title: item.title,
                quantity: item.quantity,
                price: item.price,
                days:item.days,
                time:item.fruitTime
              });

              totalSum += item.quantity * item.price;
            });
          }
        });

        setCart(cartItems);
        setTotalAmount(totalSum);
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    };

    fetchCart();
  }, [currentUser.email]);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      address: "",
      state: "",
      city: "",
      country: "",
      pincode: "",
    },
    validationSchema: shippingSchema,
    onSubmit: async (values) => {
      try {
        if (!currentUser || !currentUser.email) {
          alert("User information is not available.");
          return;
        }
    
        // Generate a unique order ID manually
        const ordersRef = collection(db, "Fruitsy", "orders","orderId");
        const orderDocRef = doc(ordersRef); // Create a new document reference with a unique ID
        const orderId = orderDocRef.id;
    
        const orderData = {
          shippingInfo: values,
          cartItems: cart,
          subtotal: totalAmount,
          shippingCost: 100,
          total: totalAmount + 100,
          date: new Date().toISOString(),
          userEmail: currentUser.email,
          orderId: orderId, // Include the orderId in the order data
        };
    
        // Add the order to the "orders" collection
        await setDoc(orderDocRef, orderData);
    
        // Add the same order to the user's "orders" collection
        const userOrdersRef = collection(db, "Fruitsy", "users", "userId", currentUser.email, "orders");
        await setDoc(doc(userOrdersRef, orderId), orderData);
    
        alert("Order placed successfully!");
        navigate("/my-orders");
      } catch (error) {
        console.error("Error placing order:", error);
      }
    }
    
  });

  return (
    <Container class1="checkout-wrapper py-5 home-wrapper-2">
      <div className="row">
        <div className="col-7">
          <div className="checkout-left-data">
            <h3 className="website-name">Checkout</h3>
            <h4 className="title total">Shipping Address</h4>
            <form
              onSubmit={formik.handleSubmit}
              className="d-flex gap-15 flex-wrap justify-content-between"
            >
              <div className="w-100">
                <select
                  className="form-control form-select"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="" disabled>
                    Select Country
                  </option>
                  <option value="India">India</option>
                </select>
                <div className="error ms-2 my-1">
                  {formik.touched.country && formik.errors.country}
                </div>
              </div>
              <div className="flex-grow-1">
                <input
                  type="text"
                  placeholder="First Name"
                  className="form-control"
                  name="firstname"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="error ms-2 my-1">
                  {formik.touched.firstname && formik.errors.firstname}
                </div>
              </div>
              <div className="flex-grow-1">
                <input
                  type="text"
                  placeholder="Last Name"
                  className="form-control"
                  name="lastname"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="error ms-2 my-1">
                  {formik.touched.lastname && formik.errors.lastname}
                </div>
              </div>
              <div className="w-100">
                <input
                  type="text"
                  placeholder="Address"
                  className="form-control"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="error ms-2 my-1">
                  {formik.touched.address && formik.errors.address}
                </div>
              </div>
              <div className="w-100">
                <input
                  type="text"
                  placeholder="Apartment, Suite, etc."
                  className="form-control"
                  name="other"
                  value={formik.values.other}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="flex-grow-1">
                <input
                  type="text"
                  placeholder="City"
                  className="form-control"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="error ms-2 my-1">
                  {formik.touched.city && formik.errors.city}
                </div>
              </div>
              <div className="flex-grow-1">
                <input
                  type="text"
                  placeholder="State"
                  className="form-control"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="error ms-2 my-1">
                  {formik.touched.state && formik.errors.state}
                </div>
              </div>
              <div className="flex-grow-1">
                <input
                  type="text"
                  placeholder="Pincode"
                  className="form-control"
                  name="pincode"
                  value={formik.values.pincode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="error ms-2 my-1">
                  {formik.touched.pincode && formik.errors.pincode}
                </div>
              </div>
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center">
                  <Link to="/cart" className="text-dark">
                    <BiArrowBack className="me-2" />
                    Return to Cart
                  </Link>
                  <Link to="/cart" className="button">
                    Continue to Shipping
                  </Link>
                  <button className="button" type="submit">
                    Place Order
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-5">
          <div className="border-bottom py-4">
            <div className="d-flex gap-10 mb-3">
              <div className="w-25">
                <h6>Quantity</h6>
              </div>
              <div className="w-50">
                <h6>Name</h6>
              </div>
              <div className="flex-grow-1 text-end">
                <h6>Price</h6>
              </div>
            </div>

            {cart.map((item) => (
              <div key={item.id} className="d-flex gap-10 mb-2 align-items-center">
                <div className="w-25 position-relative">
                  <span
                    style={{ top: "-20px" }}
                    className="badge bg-secondary text-white rounded-circle p-2 position-absolute"
                  >
                    {item.quantity}
                  </span>
                </div>
                <div className="w-50">
                  <h5 className="total-price">{item.title}</h5>
                </div>
                <div className="flex-grow-1 text-end">
                  <h5 className="total">Rs. {item.price * item.quantity}</h5>
                </div>
              </div>
            ))}
          </div>

          <div className="border-bottom py-4">
            <div className="d-flex justify-content-between align-items-center">
              <p className="total">Subtotal</p>
              <p className="total-price">Rs. {totalAmount}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0 total">Shipping</p>
              <p className="mb-0 total-price">Rs. 100</p>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center py-4">
            <h4 className="total">Total</h4>
            <h5 className="total-price">Rs. {totalAmount + 100}</h5>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Checkout;