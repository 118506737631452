import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAuth } from "../firebase/auth_context"; // Custom hook to get current user
import { getFirestore, doc, getDoc, setDoc, deleteDoc } from "firebase/firestore"; // Firestore imports
import { updateProfile as updateFirebaseProfile } from "firebase/auth"; // Firebase Auth
import { useNavigate } from "react-router-dom"; // For navigation
import { FiEdit } from "react-icons/fi";
import backgroundImage from "../images/tasty-image.png";
import "../components/Pricing.css";
import { deleteUser, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { auth, db } from "../firebase/FirebaseConfig";


const profileSchema = yup.object({
  firstname: yup.string().required("Name is required"),
  email: yup.string().email("Email should be valid").required("Email is required"),
  mobile: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should be 10 digits")
    .required("Mobile number is required"),
  billingAddress: yup.string().required("Billing address is required"),
});

const Profile = () => {
  const { currentUser } = useAuth(); // Access current user
  const navigate = useNavigate();
  const [edit, setEdit] = useState(true);
  const [avatar, setAvatar] = useState(null);
  const [initialData, setInitialData] = useState({
    firstname: "",
    email: "",
    mobile: "",
    billingAddress: "",
  });

  // Fetch profile data from Firestore
  useEffect(() => {
    const fetchProfileData = async () => {
      if (currentUser && currentUser.email) {
        const userDocRef = doc(db, "Fruitsy", "users", "userId", currentUser.email); // Correct Firestore path
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const data = userDoc.data();
          setInitialData({
            firstname: data.firstname || currentUser.firstname || "",
            email: data.email || currentUser.email || "",
            mobile: data.mobile || "",
            billingAddress: data.billingAddress || "",
          });
        } else {
          // Use Firebase Auth as fallback if Firestore document doesn't exist
          setInitialData({
            firstname: currentUser.displayName || "",
            email: currentUser.email || "",
            mobile: "",
            billingAddress: "",
          });
        }
      }
    };

    fetchProfileData();
  }, [currentUser]);

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true, // Ensures form updates when initialData changes
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      try {
        // Update Firebase Auth Profile
        await updateFirebaseProfile(currentUser, {
          displayName: values.firstname,
        });

        // Update Firestore with additional fields
        const userDocRef = doc(db, "Fruitsy", "users", "userId", values.email); // Correct Firestore path
        await setDoc(
          userDocRef,
          {
            firstname: values.firstname,
            email: values.email,
            mobile: values.mobile,
            billingAddress: values.billingAddress,
          },
          { merge: true } // Merge to avoid overwriting
        );

        setEdit(true);
        alert("Profile updated successfully");
      } catch (error) {
        console.error("Error updating profile: ", error);
        alert("Failed to update profile. Please try again.");
      }
    },
  });

  const handleAvatarChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setAvatar(URL.createObjectURL(e.target.files[0]));
    }
  };

 

  const handleDeleteAccount = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete your account? This action cannot be undone."
    );
  
    if (confirmed) {
      try {
        const currentUser = auth.currentUser; // Ensure you fetch the authenticated user
  
        if (!currentUser) {
          alert("No user is currently logged in.");
          return;
        }
  
        // Delete user document from Firestore
        const userDocRef = doc(db, "Fruitsy", "users", "userId", currentUser.email);
        await deleteDoc(userDocRef);
  
        // Delete user from Firebase Authentication
        await deleteUser(currentUser);
  
        // Navigate to home screen after deletion
        alert("Your account has been successfully deleted.");
        navigate("/"); // Ensure your `navigate` function is properly configured
      } catch (error) {
        console.error("Error deleting account: ", error);
  
        if (error.code === "auth/requires-recent-login") {
          alert(
            "Your session has expired. Please log in again and try deleting your account."
          );
          navigate("/login"); // Redirect to login screen for reauthentication
        } else {
          alert("Failed to delete account. Please try again later.");
        }
      }
    }
  };


  if (!currentUser) {
    return <div>Please log in to view your profile.</div>;
  }

  return (
    <>
      <section>
        <div className="banner1" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <h3 className="wishlist1-text">Profile</h3>
        </div>
      </section>
      <div className="profile-wrapper">
        <div className="profile-header d-flex justify-content-between align-items-center mb-4">
          <h3>Personal Information</h3>
          <FiEdit className="fs-3" onClick={() => setEdit(false)} />
        </div>
        <form onSubmit={formik.handleSubmit} className="profile-form">
          <div className="mb-3">
            <label htmlFor="firstname" className="form-label">
              Name *
            </label>
            <input
              type="text"
              name="firstname"
              id="firstname"
              className="form-control"
              disabled={edit}
              value={formik.values.firstname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.firstname && formik.errors.firstname && (
              <div className="error">{formik.errors.firstname}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email *
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="form-control"
              disabled
              value={formik.values.email}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="mobile" className="form-label">
              Mobile Number *
            </label>
            <input
              type="text"
              name="mobile"
              id="mobile"
              className="form-control"
              disabled={edit}
              value={formik.values.mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <div className="error">{formik.errors.mobile}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="billingAddress" className="form-label">
              Billing Address *
            </label>
            <textarea
              name="billingAddress"
              id="billingAddress"
              className="form-control"
              disabled={edit}
              value={formik.values.billingAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
            {formik.touched.billingAddress && formik.errors.billingAddress && (
              <div className="error">{formik.errors.billingAddress}</div>
            )}
          </div>
          {!edit && (
            <div className="text-end">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          )}
        </form>
        <div className="text-end mt-4">
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleDeleteAccount}
          >
            Delete Account
          </button>
        </div>
      </div>
    </>
  );
};

export default Profile;
