import React from 'react';
import './Testimonials.css';

const testimonials = [
  {
    id: 1,
    name: 'Leslie Alexander',
    title: 'Freelance React Developer',
    text: '“You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.”',
    stars: 5,
    image: 'path-to-leslie-image.jpg', // Replace with actual path to the image
  },
  {
    id: 2,
    name: 'Jacob Jones',
    title: 'Digital Marketer',
    text: '“Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users.”',
    stars: 5,
    image: 'path-to-jacob-image.jpg', // Replace with actual path to the image
  },
  {
    id: 3,
    name: 'Jenny Wilson',
    title: 'Graphic Designer',
    text: '“I cannot believe that I have got a brand new landing page after getting Omega. It was super easy to edit and publish.”',
    stars: 5,
    image: 'path-to-jenny-image.jpg', // Replace with actual path to the image
  },
];

const Testimonials = () => {
  return (
    <div className="testimonials-container">
      <h2 className="testimonials-heading">Our happy clients say about us</h2>
      <div className="cards">
      <div className="testimonials-scroll">
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-card">
            <div className="stars">
              {'★'.repeat(testimonial.stars)}
            </div>
            <p className="testimonial-text">{testimonial.text}</p>
              <div>
                <h4 className="client-name">{testimonial.name}</h4>
                <p className="client-title">{testimonial.title}</p>
              </div>
            </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Testimonials;
